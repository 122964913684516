body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    overflow: hidden;
}

body {
    overflow: auto;

}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-scrolling: touch;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
}

#root, .App, .ViewPort {
    width: 100%;
    height: 100%;
}

#root:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.ViewPort {
    display: block;
}

.LoadingScreen {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    transition: all 1s linear;
    pointer-events: none;
}

.DialogOverlay {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.DialogContent {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1em;
    font-size: 2.5em;
    font-family: Georgia, "Times New Roman";
    color: #ffffff;
    text-align: center;
    transition: all 0.5s linear;
    opacity: 1;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.Invisible {
    opacity: 0;
}

.SwipeIcon {
    position: relative;
    left: 15%;
    animation: slide 2s infinite;
    animation-delay: 0.5s;
    text-align: center;
    transition: all 0.5s linear;
}

@keyframes slide {
    100% {
        left: -15%;
    }
}